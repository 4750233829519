<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col cols="12" offset-lg="9" lg="3" class="d-flex justify-lg-end justify-md-end">
                <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block large rounded v-bind="attrs" v-on="on">New Institution</v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 d-flex justify-center">Create Institution</v-card-title>

                        <v-card-text class="mt-5">
                            <v-form>
                                <v-text-field clearable v-model="institution.institution_name" dense rounded solo label="Nom Institution"></v-text-field>
                                <v-text-field clearable v-model="institution.email" dense rounded solo label="Email"></v-text-field>
                                <v-text-field clearable v-model="institution.telephone" dense rounded solo label="Téléphone"></v-text-field>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn text color="success" :loading="loading" @click="create">creer institution</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="dialog = false">Annuler</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="institutions" :search="search">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="search"
                                    label="Search an institution name/institution code" class="mx-4 pt-4"
                                    append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";

import services from "../services/services"

export default {
    name:"Institutions",
    data: () => ({
        dialog: false,
        search: "",
        headers: [
            { text: "Institution Name", value: "institution_name", align: "center" },
            { text: "Institution Code", value: "institution_code", align: "center" },
            { text: "Telephone", value: "telephone", align: "center" },
            { text: "Email", value: "email", align: "center" },
            { text: "Created At", value: "created_at", align: "center" },
        ],
        loading: false,
        institution: {
            institution_name: "",
            email:"",
            telephone: ""
        }
    }),
    methods: {
        ...mapActions(['setInstitutions']),
        async create(){
            this.loading = true;
            await services.create_institution(this.institution);
            await this.setInstitutions();
            this.loading = false;
        }
    },
    computed:{
        ...mapState(['institutions'])
    },
    async mounted(){
        await this.setInstitutions();
    }
}
</script>


<style scoped>
</style>